import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import ServiceWorkerWrapper from "./ServiceWorkerWrapper";
import Delayed from "./components/Delayed";
import logo from "./logo.png";
import {BrowserRouter} from "react-router-dom";
import {SnackbarProvider} from "notistack";
import moment from "moment";
import "moment/locale/de"
import "./firebase";

// import {client} from "./client";

moment.locale("de")

const App = React.lazy(() => import('./App'));

ReactDOM.render(
    <React.StrictMode>
        <ServiceWorkerWrapper/>
        <React.Suspense fallback={
            <Delayed delay={100}>
                <img src={logo} className={"img"} width={128} height={128}/>
                <h1 className={"title"}>TimeChamp</h1>
                <h4 className={"subtitle"}>Zeiterfassung</h4>
            </Delayed>
        }>
            <BrowserRouter>
                <SnackbarProvider maxSnack={5}>
                    <App/>
                </SnackbarProvider>
                {/*<ApolloProvider client={client}>*/}
                {/*</ApolloProvider>*/}
            </BrowserRouter>
        </React.Suspense>
    </React.StrictMode>,
    document.getElementById('root')
);

// ReactDOM.render(
//     <React.StrictMode>
//         <Delayed delay={100}>
//             <img src={logo} className={"img"} width={128} height={128}/>
//             <h1 className={"title"}>TimeChamp</h1>
//             <h4 className={"subtitle"}>Zeiterfassung</h4>
//         </Delayed>
//     </React.StrictMode>,
//     document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
