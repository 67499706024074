import React, { FC, useEffect } from 'react';
import * as serviceWorker from './serviceWorkerRegistration';
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";

const ServiceWorkerWrapper: FC = () => {
    const [showReload, setShowReload] = React.useState(false);
    const [waitingWorker, setWaitingWorker] = React.useState<ServiceWorker | null>(null);

    const onSWUpdate = (registration: ServiceWorkerRegistration) => {
        setShowReload(true);
        setWaitingWorker(registration.waiting);
    };

    useEffect(() => {
        serviceWorker.register({ onUpdate: onSWUpdate });
    }, []);

    const reloadPage = () => {
        waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
        setShowReload(false);
        window.location.reload();
    };

    return (
        <Snackbar
            open={showReload}
            message="Eine neuere Version ist verfügbar!"
            onClick={reloadPage}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            action={
                <Button
                    color="inherit"
                    size="small"
                    onClick={reloadPage}
                >
                    Neu laden
                </Button>
            }
        />
    );
}

export default ServiceWorkerWrapper;
