import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyBPTxJMwR-w9ywxOwW7uNoKZ8LUVzjCUTw",
    authDomain: "timechamp.firebaseapp.com",
    projectId: "timechamp",
    storageBucket: "timechamp.appspot.com",
    messagingSenderId: "300095155959",
    appId: "1:300095155959:web:96076f59a4f3056c4cedf2",
    measurementId: "G-8C0H0ZZEG9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);