import React from "react";

interface Props {
    delay: number
}

export default class Delayed extends React.Component<Props, any> {

    timeout: NodeJS.Timeout | null = null;

    constructor(props) {
        super(props);
        this.state = {hidden: true};
    }

    componentDidMount() {
        this.timeout = setTimeout(() => {
            this.setState({hidden: false});
        }, this.props.delay);
    }

    componentWillUnmount() {
        if (this.timeout) clearTimeout(this.timeout)
    }

    render() {
        return this.state.hidden ? '' : this.props.children;
    }
}
